import { template } from "@ember/template-compiler";
import ApiSections from "./api-sections";
const SidebarApiPanels = template(`
  <div class="sidebar-sections">
    <ApiSections @collapsable={{@collapsableSections}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarApiPanels;
